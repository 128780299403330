<template>
  <v-col sm="12">
    <loader :color="companyColor" v-if="loading" />

    <v-col sm="12" v-else>
      <button
        class="tw-flex tw-items-center tw-mt-6 overview-btn"
        @click="selectTab"
      >
        <icons name="arrow-back" :color="companyColor" />

        <span class="tw-ml-4" :style="{ color: companyColor }"
          >All Managers</span
        >
      </button>

      <v-col
        sm="12"
        class="tw-bg-white tw-mt-9 tw-rounded-xl lg:tw-pl-16 tw-py-10 lg:tw-pr-40 tw-w-full"
      >
        <div
          class="tw-flex tw-w-full tw-items-center tw-justify-between tw-mb-10"
        >
          <p class="edit-header" :style="{ color: companyColor }">
            Edit Manager
          </p>
          <button @click="openDeleteModal">
            <icons name="trash" :color="companyColor" />
          </button>
        </div>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(updateTerminalManager)">
            <v-row class="upload-div tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
              <v-col sm="12" lg="4" class="">
                <div class="form-label-div">
                  <p class="label">Upload Photo</p>
                  <p class="label-info">Choose an image as a profile</p>
                </div>
              </v-col>
              <v-col sm="12" lg="8" class="">
                <button
                  class="button-logo-input"
                  type="button"
                  @click="onLogoFile()"
                  :class="image ? 'border-0' : ''"
                >
                  <v-progress-circular
                    :size="100"
                    :width="3"
                    :color="companyColor"
                    indeterminate
                    style="position: absolute;"
                    v-if="logoImageLoading"
                  ></v-progress-circular>
                  <img
                    :src="image"
                    alt=""
                    class="button-logo-img"
                    v-if="logoImage"
                  />
                  <img
                    :src="managerData.profileImage"
                    alt=""
                    class="button-logo-img"
                    v-else-if="managerData.profileImage"
                  />
                  <img
                    src="../../../assets/camera.png"
                    alt=""
                    class="button-logo-input-img"
                    v-else
                  />
                </button>

                <input
                  type="file"
                  ref="logoImage"
                  accept="image/*"
                  style="display: none"
                  @change="onLogoImagePicked"
                />
              </v-col>
            </v-row>

            <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
              <v-col sm="12" lg="4" class="">
                <div class="form-label-div">
                  <p class="label">First Name</p>
                  <p class="label-info">Type the First Name of the Manager</p>
                </div>
              </v-col>
              <v-col sm="12" lg="8" class="">
                <ValidationProvider
                  name="First Name"
                  rules="required"
                  v-slot="{ classes, errors }"
                >
                  <div class="" :class="classes">
                    <v-text-field
                      placeholder="Mason"
                      solo
                      hide-details
                      v-model="managerData.firstname"
                      autofocus
                      type="text"
                      required
                    ></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
              <v-col sm="12" lg="4" class="">
                <div class="form-label-div">
                  <p class="label">Last Name</p>
                  <p class="label-info">
                    Type the Last Name of the Manager
                  </p>
                </div>
              </v-col>
              <v-col sm="12" lg="8" class="">
                <ValidationProvider
                  name="Last Name"
                  rules="required"
                  v-slot="{ classes, errors }"
                >
                  <div class="" :class="classes">
                    <v-text-field
                      placeholder="Ryan"
                      solo
                      hide-details
                      v-model="managerData.lastname"
                      type="text"
                      required
                    ></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
              <v-col sm="12" lg="4" class="">
                <div class="form-label-div">
                  <p class="label">Email</p>
                  <p class="label-info">
                    Type the Email Address of the Manager
                  </p>
                </div>
              </v-col>
              <v-col sm="12" lg="8" class="">
                <ValidationProvider
                  name="Manager's Email"
                  v-slot="{ classes, errors }"
                >
                  <div class="" :class="classes">
                    <v-text-field
                      placeholder="ryan@gmail.com"
                      v-model="managerData.email"
                      solo
                      hide-details
                      type="email"

                    ></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
              <v-col sm="12" lg="4" class="">
                <div class="form-label-div">
                  <p class="label">Mobile</p>
                  <p class="label-info">
                    Type the Mobile Number
                  </p>
                </div>
              </v-col>
              <v-col sm="12" lg="8" class="">
                <phone-number :phone-number-exist="managerData.phoneNumber" @getNumber="getNumber"/>
              </v-col>
            </v-row>
            <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
              <v-col sm="12" lg="4" class="">
                <div class="form-label-div">
                  <p class="label">Address</p>
                  <p class="label-info">
                    Type the Manager's address
                  </p>
                </div>
              </v-col>
              <v-col sm="12" lg="8" class="">
                <ValidationProvider
                  name="Address"
                  rules="required"
                  v-slot="{ classes, errors }"
                >
                  <div class="" :class="classes" @click="handleShowAddressModal">
                    <v-text-field
                      placeholder="371,Borno Way, Alagomeji Yaba, Lagos"
                      solo
                      hide-details
                      type="text"
                      v-model="getManagerAddress"
                      required
                    ></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
              <v-col sm="12" lg="4" class="">
                <div class="form-label-div">
                  <p class="label">Street Number</p>
                  <p class="label-info">
                    Choose the Street Number in which the Terminal is located.
                  </p>
                </div>
              </v-col>
              <v-col sm="12" lg="8" class="">
                <ValidationProvider
                  name="Street Number"
                  rules="required"
                  v-slot="{ classes, errors }"
                >
                  <div class="" :class="classes">
                    <v-text-field
                      placeholder="20"
                      solo
                      hide-details
                      v-model="managerData.address.houseNumber"
                      type="text"
                      required
                    ></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
              <v-col sm="12" lg="4" class="">
                <div class="form-label-div">
                  <p class="label">Terminal</p>
                  <p class="label-info">
                    Choose the Terminal for the Manager.
                  </p>
                </div>
              </v-col>
              <v-col sm="12" lg="8" class="">
                <ValidationProvider
                  name="Terminal"
                  rules="required"
                  v-slot="{ classes, errors }"
                >
                  <div class="" :class="classes">
                    <v-combobox
                      disabled
                      :items="terminals"
                      label="Choose Terminal"
                      append-icon="mdi-chevron-down"
                      item-text="terminalName"
                      item-value="id"
                      v-model="managerData.terminalAssignedName"
                      solo
                      hide-details
                    >
                      <template v-slot:item="{item}">
                        <label>{{item.terminalName}}  {{item.address.city}}, {{item.address.state}}</label>
                      </template>
                    </v-combobox>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </v-col>
            </v-row>

            <div class="tw-flex tw-w-full tw-justify-end tw-items-center action-btn-div tw-mt-10">
              <v-btn
                text
                class="first-btn"
                :style="{
                  color: companyColor,
                  border: '1px solid' + companyColor,
                }"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                class="second-btn"
                type="submit"
                :style="{ background: companyColor }"
                :loading="doneLoading"
              >
                Update
              </v-btn>
            </div>
          </form>
        </validation-observer>
      </v-col>
    </v-col>
    <delete-modal
      :dialog="showDialog"
      @close="closeModal"
      @deleteAction="deleteTerminalManager"
      :done-loading="deleteLoading"
      description="Are you sure you want to delete this terminal manager?"
    />
    <modal section="custom-address" :address="managerData.address"
           :dialog="showAddressModal" @handleAddress="handleAddress"  @close="closeHandleModal"/>
  </v-col>
</template>

<script>
import {
  getAllCountries,
  getAllStatesByCountry,
  getAllCitiesInAState,
  uploadToCloudinary,
  getTransportCompanyTerminals,
  getManagerById,
  deleteManagerById,
  updateTerminalManager,
} from "../../../services/api/APIService";
import Loader from "../../reuseables/Loader";
import Icons from "../../reuseables/Icons";
import DeleteModal from "../../reuseables/DeleteModal";
import Lga from "../../../assets/lga";
import PhoneNumber from "../../reuseables/PhoneNumber";
import Modal from "@/components/reuseables/Modal.vue";

export default {
  name: "EditManager",
  components: {Modal, PhoneNumber, Loader, Icons, DeleteModal },
  data() {
    return {
      companyData: {},
      managerData: {},
      companyColor: "#004AAD",
      managerId: "",
      country: "",
      state: "",
      city: "",
      streetNumber: "",
      streetName: "",
      getCountriesLoading: false,
      getStatesLoading: false,
      getCitiesLoading: false,
      countries: [],
      states: [],
      cities: [],
      terminals: [],
      logoImage: "",
      logoImageUrl: "",
      logoImageLoading: false,
      image: "",
      loading: false,
      doneLoading: false,
      deleteLoading: false,
      showDialog: false,
      showAddressModal:false,

    };
  },
  computed: {
    preset() {
      return process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET;
    },
    getManagerAddress(){
      if (this.managerData.address.houseNumber) {
        return this.toSentenceCase(this.managerData.address.houseNumber) + " "
            +this.toSentenceCase(this.managerData.address.streetName) +
            ", " + this.toSentenceCase(this.managerData.address.city) + " "
            +this.toSentenceCase(this.managerData.address.state)
            + " "+this.toSentenceCase(this.managerData.address.country)
      }
      else {
        return ""
      }
    },
  },
  methods: {
    handleShowAddressModal(){
      this.showAddressModal = true
    },
    closeHandleModal(){
      this.showAddressModal = !this.showAddressModal
    },

    handleAddress(address){
      console.log(address, "the address")
      this.managerData.address = address
    },

    toSentenceCase(text) {
      text = text.toLowerCase()
      return text.replace(text.charAt(0), text.charAt(0).toUpperCase())
    },

    openDeleteModal() {
      console.log("click me");
      this.showDialog = true;
    },
    closeModal() {
      this.showDialog = false;
    },
    async deleteTerminalManager() {
      this.deleteLoading = true;
      let data = {};
      data.transportCompanyId = this.companyData.id;
      data.terminalManagerId = this.managerId;
      try {
        const response = await deleteManagerById(data);
        console.log(response);
        this.$displaySnackbar({
          message: response.data.details,
          success: true,
        });
        this.deleteLoading = false;
        this.selectTab();
        this.closeModal();
      } catch (error) {
        console.log(error);
        this.$displaySnackbar({
          message: error.response.data.details[0],
          success: false,
        });
        this.deleteLoading = false;
        this.closeModal();

        console.log(error.response);
      }
    },
    selectTab() {
      console.log("i'm here");
      this.$store.dispatch(
        "managerComponent/setCurrentComponent",
        "allManagers"
      );
      sessionStorage.setItem("managerComponent", "allManagers");
    },
    onLogoFile() {
      this.$refs.logoImage.click();
    },
    async onLogoImagePicked(event) {
      const files = event.target.files[0];
      try {
        this.logoImageLoading = true;
        const fileReader = new FileReader();
        fileReader.addEventListener("load", async () => {
          this.recoveredImg = "";
          this.image = fileReader.result;
          this.logoImage = files.name;
          let newData = {
            file: this.image,
            upload_preset: this.preset,
          };
          const response = await uploadToCloudinary(newData);
          console.log(response);
          this.managerData.profileImage = response.data.url;
          this.logoImageLoading = false;
        });
        fileReader.readAsDataURL(files);
      } catch (err) {
        console.log(err.response);
      }
    },

    async getCountries() {
      try {
        this.getCountriesLoading = true;
        const response = await getAllCountries();
        // console.log(response)
        this.countries = response.data.data;
        this.getCountriesLoading = false;
      } catch (err) {
        console.log(err);
        this.getCountries = false;
      }
    },
    async getStateChange() {
      this.managerData.address.state = "";
      this.managerData.address.city = "";
      try {
        // console.log("ok");
        this.getStatesLoading = true;
        let data = {
          country: this.managerData.address.country,
        };
        const response = await getAllStatesByCountry(data);
        this.states = response.data.data.states;
        if (this.managerData.address.country.name === "Nigeria" ||
            this.managerData.address.country === "Nigeria"){
          this.states.push({name: 'Rivers State'})
        }
        this.getStatesLoading = false;
      } catch (err) {
        console.log(err.response);
      }
    },
    async getState() {
        try {
          this.getStatesLoading = true;
          let data = {};
          if (this.managerData.address.country.name) {
            data.country = this.managerData.address.country.name;
          } else {
            data.country = this.managerData.address.country;
          }
          const response = await getAllStatesByCountry(data);
          this.states = response.data.data.states;
          if (this.managerData.address.country.name === "Nigeria" ||
              this.managerData.address.country === "Nigeria"){
            this.states.push({name: 'Rivers State'})
          }
          this.getStatesLoading = false;
        } catch (err) {
          console.log(err);
        }

    },
    async getCity() {
      if (this.managerData.address.country.name === "Nigeria" ||
          this.managerData.address.country === "Nigeria") {
        this.cities = Lga[this.managerData.address.state.name || this.managerData.address.state]
      } else {
        try {
          this.getCitiesLoading = true;
          let data = {};
          if (this.managerData.address.country.name){
            data.country=this.managerData.address.country.name
            data.state= this.managerData.address.state.name
          }
          else {
            data.country=this.managerData.address.country
            data.state= this.managerData.address.state
          }
          const response = await getAllCitiesInAState(data);
          // console.log(response);
          this.cities = response.data.data;
          this.getCitiesLoading = false;
        } catch (err) {
          console.log(err.response);
          this.cities = [];
          this.getCitiesLoading = false;
        }
      }
    },

    async getManagerById() {
      try {
        const response = await getManagerById(
          this.companyData.id,
          this.managerId
        );
        this.managerData = await response.data;
        await this.getState();
        await this.getCity();

        this.loading = false;
        console.log(response);
      } catch (error) {
        console.log(error.response);
      }
    },
    async getAllTerminals() {
      try {
        const response = await getTransportCompanyTerminals(
          this.companyData.id
        );
        this.terminals = response.data;
      } catch (error) {
        console.log(error.response);
        this.$displaySnackbar({
          message: error.response.data.details[0],
          success: false,
        });
      }
    },

    async updateTerminalManager() {
      this.doneLoading = true;
      try {
        await updateTerminalManager(
          this.managerData,
          this.companyData.id,
          this.managerData.terminalAssignedId,
          this.managerId
        );
        this.$displaySnackbar({
          message: "Manager Updated Successfully",
          success: true,
        });
        this.selectTab();
        this.doneLoading = false;
      } catch (error) {
        this.$displaySnackbar({
          message: error.response.data.details[0],
          success: false,
        });
        this.doneLoading = false;
        console.log(error.response);
      }
    },
    getNumber(number){
      this.managerData.phoneNumber=number
    }
  },
  mounted() {
    this.getCountries();
  },
  async created() {
    this.loading = true;
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    let managerId = sessionStorage.getItem("managerId");
    if (managerId) {
      this.managerId = managerId;
    } else {
      return this.selectTab();
    }

    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }
    await this.getAllTerminals();

    await this.getManagerById();
    if (this.managerData.address.country){
      await this.getState()
    }
    if (this.managerData.address.state){
      await this.getCity()
    }
  },
};
</script>

<style lang="scss" scoped>
.upload-div .upload-div-text {
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4f4f;
  margin-bottom: 20px;
}

.button-logo-input {
  display: flex;
  position: relative;
  border-radius: 100%;
  align-items: center;
  height: 84px;
  width: 84px;
  justify-content: center;
  border: 1px solid rgba(189, 189, 189, 0.3);
}
.button-logo-input .button-logo-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.button-logo-input .button-logo-input-img {
  width: 25px;
  height: 25px;
}
.action-btn-div {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;

  .first-btn {
    width: 120px;
    height: 32px;
    color: #004aad;
    border: 1px solid #004aad;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .second-btn {
    width: 115px;
    height: 32px;
    background: #004aac;
    border-radius: 6px;
    color: white;
    margin-left: 20px;
  }
}

.overview-btn {
  border: none !important;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}
.edit-header {
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 74, 173, 1);
}

.form-label-div {
  .label {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
    color: rgba(79, 79, 79, 1);
  }

  .label-info {
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(79, 79, 79, 1);
    margin-bottom: 0;
  }
}
</style>
