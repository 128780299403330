var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"sm":"12"}},[_c('button',{staticClass:"tw-flex tw-items-center tw-mt-6 overview-btn",style:({ color: _vm.companyColor }),on:{"click":_vm.backToAllManagers}},[_c('icons',{attrs:{"name":"arrow-back","color":_vm.companyColor}}),_c('span',{staticClass:"tw-ml-4"},[_vm._v("All Managers")])],1),_c('v-col',{staticClass:"\n      tw-bg-white tw-mt-9 tw-rounded-xl lg:tw-pl-16 tw-py-10 lg:tw-pr-40 tw-w-full\n    ",attrs:{"sm":"12"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addManager)}}},[_c('div',{staticClass:"\n            tw-flex tw-w-full tw-items-center tw-justify-between tw-mb-10\n          "},[_c('p',{staticClass:"edit-header",style:({ color: _vm.companyColor })},[_vm._v(" New Manager ")]),(_vm.managerData.id)?_c('icons',{attrs:{"name":"trash","color":_vm.companyColor}}):_vm._e()],1),_c('v-row',{staticClass:"upload-div tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Upload Photo")]),_c('p',{staticClass:"label-info"},[_vm._v("Choose Manager's profile image")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('button',{staticClass:"button-logo-input",class:_vm.image ? 'border-0' : '',attrs:{"type":"button"},on:{"click":function($event){return _vm.onLogoFile()}}},[(_vm.logoImageLoading)?_c('v-progress-circular',{staticStyle:{"position":"absolute"},attrs:{"size":100,"width":3,"color":_vm.companyColor,"indeterminate":""}}):_vm._e(),(_vm.logoImage)?_c('img',{staticClass:"button-logo-img",attrs:{"src":_vm.image,"alt":""}}):_c('img',{staticClass:"button-logo-input-img",attrs:{"src":require("../../../assets/camera.png"),"alt":""}})],1),_c('input',{ref:"logoImage",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onLogoImagePicked}})])],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("First Name")]),_c('p',{staticClass:"label-info"},[_vm._v("Type the First Name of the Manager")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"placeholder":"Tunji","autofocus":"","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.managerData.firstname),callback:function ($$v) {_vm.$set(_vm.managerData, "firstname", $$v)},expression:"managerData.firstname"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Last Name")]),_c('p',{staticClass:"label-info"},[_vm._v("Type the Last Name of the Manager")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"placeholder":"Oluwale","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.managerData.lastname),callback:function ($$v) {_vm.$set(_vm.managerData, "lastname", $$v)},expression:"managerData.lastname"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Email (Optional)")]),_c('p',{staticClass:"label-info"},[_vm._v("Type the Email Address of the Manager")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"Manager's Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"placeholder":"tunji@companyemail.com","solo":"","hide-details":"","type":"email"},model:{value:(_vm.managerData.email),callback:function ($$v) {_vm.$set(_vm.managerData, "email", $$v)},expression:"managerData.email"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Mobile")]),_c('p',{staticClass:"label-info"},[_vm._v("Type the Mobile Number of the Manager")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('phone-number',{attrs:{"phone-number-exist":_vm.managerData.phoneNumber},on:{"getNumber":_vm.getNumber}})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Address")]),_c('p',{staticClass:"label-info"},[_vm._v("Type the Manager's address.")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-cursor-pointer",class:classes,on:{"click":_vm.handleShowAddressModal}},[_c('v-text-field',{attrs:{"placeholder":"371,Borno Way, Alagomeji Yaba, Lagos","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.getManagerAddress),callback:function ($$v) {_vm.getManagerAddress=$$v},expression:"getManagerAddress"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Terminal")]),_c('p',{staticClass:"label-info"},[_vm._v("Choose the Terminal for the Manager.")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"Terminal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-combobox',{attrs:{"items":_vm.terminals,"placeholder":"Choose Terminal","append-icon":"mdi-chevron-down","item-text":"terminalName","item-value":"id","solo":"","hide-details":"","color":_vm.companyColor},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.terminalName)+" "+_vm._s(item.address.city)+", "+_vm._s(item.address.state))])]}}],null,true),model:{value:(_vm.terminalId),callback:function ($$v) {_vm.terminalId=$$v},expression:"terminalId"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('div',{staticClass:"\n            tw-flex tw-w-full tw-justify-end tw-items-center\n            action-btn-div\n            tw-mt-10\n          "},[_c('v-btn',{staticClass:"first-btn",style:({
              color: _vm.companyColor,
              border: '1px solid' + _vm.companyColor,
            }),attrs:{"text":""},on:{"click":_vm.backToAllManagers}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"second-btn",style:({ background: _vm.companyColor }),attrs:{"text":"","type":"submit","loading":_vm.doneLoading}},[_vm._v(" Done ")])],1)],1)]}}])})],1),_c('modal',{attrs:{"section":"custom-address","address":_vm.managerData.address,"dialog":_vm.showAddressModal},on:{"handleAddress":_vm.handleAddress,"close":_vm.closeHandleModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }