<template>
  <v-col sm="12">
    <loader :color="companyColor" v-if="loading" />
    <v-col sm="12" class="tw-p-0 tw-flex-col" v-else>
      <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-justify-between">
        <icon-and-text
          :icon="managerPlus"
          title="New Manager"
          desc="Add a Manager"
          :width="$vuetify.breakpoint.mdAndUp ? '25%' : '100%'"
          @clickAction="newTerminalTab"
        />
        <icon-and-text
          :icon="terminalDel"
          :title="managers.length ? managers.length.toString() : 'Nil'"
          desc="All terminal manager"
          :width="$vuetify.breakpoint.mdAndUp ? '25%' : '100%'"
        />
        <image-and-text-two
          :showImage="false"
          :title="mostRecent ? mostRecent.terminalAssignedName : 'Nil'"
          labelOne="Email: "
          labelTwo="Mobile: "
          :valueOne="mostRecent ? mostRecent.email : 'Nil'"
          :valueTwo="mostRecent ? '+' + mostRecent.phoneNumber : 'Nil'"
          :width="$vuetify.breakpoint.mdAndUp ? '25%' : '100%'"
          :height="$vuetify.breakpoint.mdAndUp ? '100px' : 'auto'"
        />
      </div>
      <div
        class="
          tw-flex tw-w-full tw-flex-row tw-justify-between tw-py-5 tw-mt-8
          table-container-div
          tw-px-1 tw-pl-8
        "
        style="background: #fdfffc"
      >
        <div class="table-container tw-pr-8">
          <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
            <p class="table-header" :style="{ color: companyColor }">
              All Managers
            </p>
            <div class="tw-flex tw-flex-row tw-justify-center tw-items-center">
              <div
                class="mr-2"
                style="
                  border-radius: 10px;
                  border: 1px solid rgba(79, 79, 79, 0.08);
                "
              >
                <v-text-field
                  placeholder="Search"
                  class="p-0"
                  solo
                  flat
                  hide-details
                  v-model="search"
                >
                  <template #prepend>
                    <v-icon :color="companyColor" class="ml-5">
                      mdi-magnify
                    </v-icon>
                  </template>
                  <template #append>
                    <v-icon :color="companyColor"> mdi-filter-variant </v-icon>
                  </template>
                </v-text-field>
              </div>
            </div>
          </div>

          <v-data-table
            :headers="headers"
            v-model="selectedRow"
            :mobile-breakpoint="0"
            :search="search"
            item-key="id"
            :single-select="singleSelect"
            height="available"
            :hide-default-footer="managers.length <= 10"
            :items="managers"
            :no-data-text="not_found"
            class="mt-4 mx-1 table"
            :checkbox-color="companyColor"
            @click:row="viewSingle"
          >
            <template #header.sno>
              <span class="status">S/N</span>
            </template>
            <template v-slot:[`item.sno`]="{ item }">
              <span class="td-style">{{ item.sno }}</span>
            </template>
            <template #header.profileImage>
              <span class="status">Image</span>
            </template>
            <template v-slot:[`item.profileImage`]="{ item }">
              <img
                :src="item.profileImage"
                alt="terminal"
                v-if="item.profileImage"
              />
              <div
                class="tw-flex tw-justify-center tw-items-center no-image"
                v-else
                :style="{ backgroundColor: companyColor }"
                @click="selectSingle(item.id)"
              >
                <p class="tw-text-center tw-p-0 tw-m-0 tw-mb-o">
                  {{ item.firstname[0] }}
                </p>
              </div>
            </template>
            <template #header.firstname>
              <span class="status">First Name</span>
            </template>
            <template v-slot:[`item.firstname`]="{ item }">
              <span class="td-style">{{ item.firstname }}</span>
            </template>

            <template #header.lastname>
              <span class="status">Last Name</span>
            </template>
            <template v-slot:[`item.lastname`]="{ item }">
              <span class="td-style">{{ item.lastname }}</span>
            </template>
            <template #header.email>
              <span class="status">Email</span>
            </template>
            <template v-slot:[`item.email`]="{ item }">
              <span class="td-style">{{ item.email }}</span>
            </template>
            <template #header.mobile>
              <span class="status">Mobile</span>
            </template>
            <template v-slot:[`item.mobile`]="{ item }">
              <span class="td-style">{{ item.phoneNumber }}</span>
            </template>
            <template #header.country>
              <span class="status">Country</span>
            </template>
            <template v-slot:[`item.country`]="{ item }">
              <span class="td-style">{{ item.address.country }}</span>
            </template>
            <template #header.terminal>
              <span class="status">Terminal</span>
            </template>
            <template v-slot:[`item.terminal`]="{ item }">
              <span class="td-style">{{ item.terminalAssignedName }}</span>
            </template>
            <template #header.actions>
              <span class="status"></span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu bottom offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <td class="d-flex justify-center">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon :color="companyColor">mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </td>
                </template>
                <v-list>
                  <v-list-item class="" @click="selectEditTab(item.id)">
                    <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex tw-cursor-pointer"
                    >
                      <img
                        src="@/assets/icons/edit-icon.svg"
                        alt=""
                        class="tw-mr-2 icon-size"
                      />
                      Edit
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="closeModal(item.id)">
                    <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex"
                    >
                      <img
                        src="@/assets/icons/del-icon.svg"
                        alt=""
                        class="tw-mr-2 icon-size"
                      />
                      Delete
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-col>
    <delete-modal
      :dialog="showDialog"
      @close="closeModal"
      @deleteAction="deleteTerminalManager"
      :done-loading="dialogLoading"
      description="Are you sure you want to delete this terminal manager?"
    />
  </v-col>
</template>

<script>
import IconAndText from "../IconAndText";
import ImageAndTextTwo from "../ImageAndTextTwo";
import managerPlus from "@/assets/icons/addterminalmanager.png";
import terminalDel from "@/assets/icons/allterminalmanager.png";
import locationImg from "@/assets/model.jpg";
import {
  deleteManagerById,
  getCompanyManagers,
} from "../../../services/api/APIService";
import Loader from "../../reuseables/Loader";
import DeleteModal from "../../reuseables/DeleteModal";
import dayjs from "dayjs";

export default {
  name: "AllManagerDashboard",
  components: { DeleteModal, IconAndText, ImageAndTextTwo, Loader },
  data() {
    return {
      managerPlus,
      terminalDel,
      locationImg,
      companyData: {},
      search: "",
      companyColor: "#004AAD",
      singleSelect: false,
      headers: [
        {text: "",value: "sno"},
        { text: "", value: "profileImage" },
        { text: "", value: "firstname" },
        { text: "", value: "lastname" },
        { text: "", value: "email" },
        { text: "", value: "mobile" },
        { text: "", value: "country" },
        { text: "", value: "terminal" },
        { text: "", value: "actions", align: "end" },
      ],
      managers: [],
      selectedRow: [],
      not_found: "Terminal managers will display here",
      loading: false,
      mostRecent: {},
      showDialog: false,
      managerId: "",
      dialogLoading: false,
    };
  },
  methods: {
    closeModal(managerId) {
      this.showDialog = !this.showDialog;
      if (managerId) {
        this.managerId = managerId;
      }
    },
    selectEditTab(id) {
      this.$store.dispatch(
        "managerComponent/setCurrentComponent",
        "editManager"
      );
      sessionStorage.setItem("managerComponent", "editManager");
      sessionStorage.setItem("managerId", id);
    },
    selectTab() {
      // console.log("i'm here");
      this.$store.dispatch(
        "terminalComponent/setCurrentComponent",
        "terminalDashboard"
      );
      sessionStorage.setItem("terminalComponent", "terminalDashboard");
    },
    viewSingle(item) {
      this.$store.dispatch(
        "managerComponent/setCurrentComponent",
        "singleManager"
      );
      sessionStorage.setItem("managerComponent", "singleManager");
      sessionStorage.setItem("managerId", item.id);
    },
    selectSingle(id) {
      // console.log("i'm here");
      this.$store.dispatch(
        "managerComponent/setCurrentComponent",
        "singleManager"
      );
      sessionStorage.setItem("managerComponent", "singleManager");
      sessionStorage.setItem("slugId", id);
    },
    newTerminalTab() {
      this.$store.dispatch(
        "managerComponent/setCurrentComponent",
        "addManager"
      );
      sessionStorage.setItem("managerComponent", "addManager");
    },
    async getCompanyManagers() {
      this.loading = true;
      try {
        const response = await getCompanyManagers(this.companyData.id);
        console.log(response);
        this.managers = response.data;

        this.mostRecent = this.managers[this.managers.length - 1];
        this.managers.sort(function (a, b) {
          return dayjs(b.createdAt).toDate() - dayjs(a.createdAt).toDate();
        });
        this.managers.forEach((manager,index)=>manager.sno=index+1)
        this.loading = false;
      } catch (err) {
        console.log(err.response);
        this.loading = false;
      }
    },
    //
    // async getTerminalById(){
    //
    // },
    async deleteTerminalManager() {
      this.dialogLoading = true;
      let data = {};
      data.transportCompanyId = this.companyData.id;
      data.terminalManagerId = this.managerId;
      console.log(data, "... in method");
      await deleteManagerById(data)
        .then(() => {
          this.dialogLoading = false;
          this.closeModal();
          this.getCompanyManagers();
        })
        .catch((err) => {
          console.log(err.response);
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });
          this.dialogLoading = false;
        });
    },
  },
  async created() {
    this.loading = true;
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }
    await this.getCompanyManagers();
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
}

.table-container-div {
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  .table-container {
    width: 100%;
    .table-container-header {
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #004aad;
    }
  }
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
}

.no-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;

  p {
    font-family: "Inter", serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0;
    margin-bottom: 0 !important;
    color: rgba(255, 255, 255, 1);
  }
}

img {
  width: 32px;
  height: 32px;
  border-radius: 5px;
}

::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
  padding: 0 0 0 5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  padding: 0 0 0 5px;
}
.icon-size {
  height: 16px;
  width: auto;
}

::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar {
  background: transparent !important;
  width: auto;
  height: 2px;
}
::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 1px !important;
  border-radius: 10px;
}
</style>
