<template>
  <Component v-bind:is="displayTerminalComponent()" />
</template>

<script>
import TerminalDashboard from "./terminal/ViewTerminalDashboard.vue";
import AllManagers from "./terminalManager/AllManagerDashboard.vue";
import SingleManager from "./terminalManager/SingleManager.vue";
import EditManager from "./terminalManager/EditManager";
import AddManager from "./terminalManager/AddManager";
import { mapGetters } from "vuex";

export default {
  name: "ViewManagerDashboardRoute",
  computed: {
    ...mapGetters("managerComponent", ["currentComponent"])
  },
  methods: {
    displayTerminalComponent() {
      let component = this.currentComponent;
      if (component === "terminalDashboard") {
        return TerminalDashboard;
      } else if (component === "allManagers") {
        return AllManagers;
      } else if (component === "singleManager") {
        return SingleManager;
      } else if (component === "editManager") {
        return EditManager;
      } else if (component === "addManager") {
        return AddManager;
      }
    }
  },
  created() {
    let component = sessionStorage.getItem("managerComponent");
    if (!component) {
      sessionStorage.setItem("managerComponent", "allManagers");
      this.$store.dispatch("managerComponent/setCurrentComponent", "allManagers");
      this.displayTerminalComponent()
    } else {
      this.$store.dispatch("managerComponent/setCurrentComponent", component);
      this.displayTerminalComponent()
    }
  }
};
</script>

<style lang="scss" scoped></style>
